<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Détails du block : {{ title ? title.fr : '' }}
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors && errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title ? error.title : error.message }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <ImageHandler
              :key="generateUniqueId()"
              :entity-data="entityData"
              image-label="Photo principale"
              image-name="mainImage"
              :icons="icons"
              :valid="valid"
              :loading="loading"
              :success-message="successMessage"
              :snackbar="snackbar"
              @update="updateEntityData($event)"
            ></ImageHandler>

            <v-divider class="v-divider-entity mt-2 mb-5"></v-divider>

            <v-text-field
              v-model="title[currentLocale]"
              label="Titre"
              class="mb-5"
              outlined
              dense
              placeholder="Titre"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-text-field
              v-model="slug[currentLocale]"
              label="Slug"
              class="mb-5"
              outlined
              dense
              placeholder="Slug"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-text-field
              v-model="url[currentLocale]"
              label="Url"
              class="mb-5"
              outlined
              dense
              placeholder="Url"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>

            <v-switch
              v-model="enabled"
              :label="'Etat : ' + (enabled === true ? 'activé' : 'désactivé')"
            ></v-switch>

            <div class="content-ckeditor">
              <ckeditor
                v-model="description[currentLocale]"
                :editor="editor"
                :config="editorConfig"
                height="10"
                row="50"
              />
              <country-flag
                class="inner-input-flag content-flag"
                :country="currentLocale === 'en' ? 'gb' : currentLocale"
                size="normal"
                shadow
              />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'blockList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import config from '../../../config'
import { mdiEyeOutline, mdiEyeOffOutline, mdiCameraOutline, mdiMinusBox, mdiPencil, mdiCompassRose, mdiThumbUp, mdiSkiWater } from '@mdi/js'
import LocalSwitcher from '@/components/Common/LocalSwitcher'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import FullEditor from 'ckeditor5-custom-build/build/ckeditor'
import ImageHandler from '@/components/Common/ImageHandler.vue'

export default {
  components: {
    ImageHandler,
    LocalSwitcher,
    Snackbar,
    Loader,
    ckeditor: CKEditor.component
  },
  data () {
    return {
      valid: false,
      currentLocale: 'fr',
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCameraOutline,
        mdiMinusBox,
        mdiPencil,
        mdiCompassRose,
        mdiSkiWater,
        mdiThumbUp
      },
      errors: [],
      id: [],
      title: [],
      description: [],
      slug: [],
      url: [],
      enabled: false,
      snackbar: false,
      successMessage: null,
      loading: false,
      editor: FullEditor,
      editorConfig: {
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        simpleUpload: {
          uploadUrl: config.apiUrl + '/api/medias',
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }
      },
      entityData: {
        id: null,
        enabled: false,
        currentLocale: 'fr',
        defaultLocale: 'fr',
        newTranslations: {},
        mainImage: {
          id: null,
          title: null
        }
      }
    }
  },
  created () {
    this.getBlock()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (!this.title.fr) {
        this.errors.push({ title: 'Le titre français doit être renseigné.' })

        return
      }

      if (this.valid) {
        this.updateBlock()
      }
    },
    getBlock () {
      const blockId = this.$route.params.blockId

      this.valid = false
      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/content/blocks/' + blockId, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data
            this.entityData.id = data.id
            this.enabled = data.enabled

            if (data.mainImage) {
              this.entityData.mainImage = data.mainImage
            }

            const translations = data.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.title[currentKey] = currentTranslation.title
              this.slug[currentKey] = currentTranslation.slug
              this.description[currentKey] = currentTranslation.description
              this.url[currentKey] = currentTranslation.url
            }

            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    updateBlock () {
      this.valid = false
      this.loading = true

      this.handleTranslations([
        'id',
        'title',
        'slug',
        'description',
        'url'
      ])

      this.entityData.enabled = this.enabled

      Vue.prototype.$http
        .put(config.apiUrl + '/api/content/blocks/' + this.entityData.id, this.entityData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            const translations = data.translations

            for (const [currentKey] of Object.entries(translations)) {
              let currentTranslation = translations[currentKey]

              this.id[currentKey] = currentTranslation.id
              this.title[currentKey] = currentTranslation.title
              this.slug[currentKey] = currentTranslation.slug
              this.description[currentKey] = currentTranslation.description
              this.url[currentKey] = currentTranslation.url
            }

            this.snackbar = true
            this.successMessage = 'Block mis à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data

            if (data.violations) {
              this.errors = data.violations
            } else {
              this.errors = data
            }
          }

          this.valid = true
          this.loading = false
        })
    },
    handleTranslations (attributes) {
      for (let i = 0; i < attributes.length; i++) {
        const currentAttribute = attributes[i]

        for (const [currentKey, currentValue] of Object.entries(this[currentAttribute])) {
          let translation = {
            locale: currentKey,
            empty: false
          }

          translation[currentAttribute] = currentValue

          const currentObjState = this.entityData.newTranslations[currentKey]

          this.entityData.newTranslations[currentKey] = {...currentObjState, ...translation}
        }
      }
    },
    updateEntityData (updatedData) {
      this.entityData = updatedData
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    }
  }
}
</script>
